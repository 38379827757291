import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import Footer from "./footer"
import "../styles/main.scss"

const Layout = ({ className, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
		<>
			<Header siteTitle={data.site.siteMetadata.title} />
			<main className={className}>{children}</main>
			<Footer />
		</>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
