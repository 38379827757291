import React from "react";
import { Link, useStaticQuery, graphql } from 'gatsby'
import { FaLinkedinIn, FaFacebookF, FaTwitter } from "react-icons/fa";

import { icon_file, icon_mail, icon_phone } from '../images/footer';
import { default as logos_ocupacio } from '../images/ocupacio-transformacio.png';

const Footer = props => {

    const getData = graphql`{
        allWordpressPost(limit: 4) {
            edges {
                node {
                    id
                    title
                    slug
                    categories {
                        slug
                    }                    
                }
            }
        }
    } `
    const data = useStaticQuery(getData);

    return (
        <footer className="footer">
            <div className="container">
                <div className="row">

                    <div className="col-sm-6 col-md-4 col-lg-3 ">
                        <h3>Menú</h3>
                        <ul className="le-column">
                            <li>
                                <Link to="/soporte-informatico" title="Soporte informático">Soporte informático</Link>
                            </li>
                            <li>
                                <Link to="/informix" title="Informix">Informix</Link>
                            </li>
                            <li>
                                <Link to="/gestion-tiempo" title="Planificacion de personal">Planificacion de personal</Link>
                            </li>
                            <li>
                                <Link to="/blog" title="Blog">Blog</Link>
                            </li>
                        </ul>

                    </div>

                    <div className="col-sm-6 col-md-4 col-lg-3 ">
                        <h3>Blog</h3>
                        <ul className="flex-column">
                            <li>
                                <Link to="/blog/blog-cloud" title="Cloud">Cloud</Link>
                            </li>
                            <li>
                                <Link to="/blog/blog-ibm-informix" title="IBM Informix">IBM Informix </Link>
                            </li>
                            <li>
                                <Link to="/blog/it" title="IT">IT</Link>
                            </li>
                            <li>
                                <Link to="/blog/blog-planificacion-personal" title="Planificacion de personal">Planificacion de personal </Link>
                            </li>
                            <li>
                                <Link to="/blog/productividad" title="Productividad">Productividad</Link>
                            </li>
                            <li>
                                <Link to="/blog/tech" title="Tech">Tech</Link>
                            </li>
                        </ul>
                    </div>

                    <div className="col-sm-6 col-md-4 col-lg-3 ">
                        <h3>Últimos posts</h3>
                        <ul className="flex-column ultimos-posts">
                            {data.allWordpressPost.edges.map((page, index) => (
                                <li key={index}>
                                    <Link to={`/${page.node.categories[0].slug}/${page.node.slug}`}>{page.node.title}</Link>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="col-sm-6 col-md-4 col-lg-3">
                        <h3>Contacta con nosotros</h3>
                        <ul className="flex-column footer-contact">
                            <li>
                                <span className="footer-icon">
                                    <img src={icon_phone} alt="phone" />
                                </span>
                                <span className="footer-info">93 247 99 59</span>
                            </li>
                            <li>
                                <a href="mailto:info@dms.cat" title="email">
                                    <span className="footer-icon">
                                        <img src={icon_mail} alt="email" />
                                    </span>
                                    <span className="footer-info">info@dms.cat</span>
                                </a>
                            </li>
                            <li>
                                <Link to="/contacto" title="formulario">
                                    <span className="footer-icon">
                                        <img src={icon_file} alt="form" />
                                    </span>
                                    <span className="footer-info">Formulario de contacto</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>


                <ul className="list-social">
                    <li className="list-item">
                        <a href="https://www.linkedin.com/company/dms-services-s-l-/" target="_blank" rel="noopener noreferrer" title="linkedin">
                            <FaLinkedinIn aria-label="icon linkedin" />
                        </a>
                    </li>
                    <li className="list-item">
                        <a href="https://www.facebook.com/dmsservicesbcn/" target="_blank" rel="noopener noreferrer" title="facebook">
                            <FaFacebookF aria-label="icon facebook" />
                        </a>
                    </li>
                    <li className="list-item">
                        <a href="https://twitter.com/_dmsservices" target="_blank" rel="noopener noreferrer" title="twitter">
                            <FaTwitter aria-label="icon twitter" />
                        </a>
                    </li>
                </ul>

                <img className="logos-subvencio" src={logos_ocupacio} alt="logos" />


                <ul className="list-legal">
                    <li>
                        <span className="copyright">2023 © VENTURAE CAP S.L.</span>
                        C/Tuset 8 3º 4ª 08006 Barcelona (España)
                    </li>
                    <li>
                        <Link to="/politica-privacidad" title="Política de privacidad">Política de privacidad</Link>
                        <Link to="/politica-cookies" title="Política de cookies">Política de cookies</Link>
                    </li>
                </ul>
            </div>
        </footer>
    )
}
export default Footer
