import { Link } from "gatsby";
import React, { useState } from 'react';
import logo from '../images/logo-dms.png';
import { FiMenu, FiX, FiPhone } from "react-icons/fi";


const Menu = () => (
  <ul>
    <li className="menu-home"><Link activeClassName="active" className="menu-item" title="Inicio" to="/">Inicio DMS</Link></li>
    <li><Link activeClassName="active" className="menu-item" title="Soporte informático" to="/soporte-informatico">Soporte informático</Link></li>
    <li><Link activeClassName="active" className="menu-item" title="Informix" to="/informix">Informix</Link></li>
    <li><Link activeClassName="active" className="menu-item" title="Gestión del tiempo" to="/gestion-tiempo">Gestión del tiempo</Link></li>
    <li><Link activeClassName="active" className="menu-item" title="Blog" to="/blog">Blog</Link></li>
    <li><Link activeClassName="active" className="btn btn-contact" title="Contacta con DMS" to="/contacto">Contacto</Link></li>
  </ul>
);

const Header = props => {
  const [isNavVisible, setIsNavVisible] = useState(false);

  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  }

  const closeNav = () => {
    setIsNavVisible(false);
  }

  return (
    <>
      <header className="header">
        <div className="header-top">
          <div className="container">
            <div className="kit-digital">
              ¿Conoces las soluciones de DMS incluidas en el programa Kit Digital?
              <Link className="btn btn-topnav" to="/kit-digital">Saber más</Link>
            </div>

            <div className="header-info">
              <span className="header-horario">L a V de 9 a 18h</span>
              <span className="header-telefono"><FiPhone aria-label="icon phone" /> 93 247 99 59</span>
            </div>
          </div>
        </div>

        <div className="header-container container">
          <button type="button" title="Menu" onClick={toggleNav} className="btn btn-transparent btn-menu"><FiMenu aria-label="Menu" /></button>

          <div className="header-main">
            <Link className="header-logo-link" to="/">
              <img className="header-logo" src={logo} alt="Logo DMS" title="Logo DMS" />
            </Link>
          </div>

          <div className="header-menu">
            <Menu />
          </div>

        </div>
      </header>

      {isNavVisible && (
        <>
          <div className="sidebar">
            <button className="btn-close-sidebar btn-transparent" onClick={closeNav} type="button"><FiX />{''}</button>
            <Menu />
          </div>
          <div className="sidebar-overlay" role="menu" tabIndex={0} onClick={() => { }} onKeyDown={closeNav} />
        </>
      )
      }
    </>

  );
};

export default Header
